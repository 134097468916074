import React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Linkedin } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { Dirigeant, DirigeantJT, SocietyPicture } from './../../../../src/assets/img/society';
import './backaltered.css';

const BackAltered = (): React.JSX.Element => {
    const { t, i18n } = useTranslation();
    const { pathname } = useLocation();
    const [isVisibleEllipseTop, setIsVisibleEllipseTop] = React.useState(false);
    const [isVisibleEllipseBottom, setIsVisibleEllipseBottom] = React.useState(false);
    const [isVisibleCoverCircle, setIsVisibleCoverCircle] = React.useState(false);
    const [isVisibleTopGradient, setIsVisibleTopGradient] = React.useState(false);
    const [isVisibleBottomGradient, setIsVisibleBottomGradient] = React.useState(false);
    const [isVisibleFullGradient, setIsVisibleFullGradient] = React.useState(false);
    const [isVisibleSMedia, setIsVisibleSMedia] = React.useState(false);
    const _pathname = pathname.replace(/\/$/, "").split("/")[1];

    const getBodyHeight = () => {
        const getBody = document.querySelector('#root');
        const getBAlt = document.querySelector('.b-alt span');
        if (getBody) {
            const bodyHeight = getBody.clientHeight;
            getBAlt?.setAttribute("style", `height: ${bodyHeight}px;`);
        }
    };

    useEffect(() => {
        getBodyHeight();
        setIsVisibleEllipseTop(false);
        setIsVisibleEllipseBottom(false);
        setIsVisibleCoverCircle(false);
        setIsVisibleTopGradient(false);
        setIsVisibleBottomGradient(false);
        setIsVisibleFullGradient(false);
        setIsVisibleSMedia(false);
         switch (_pathname) {
             case "societe":
                 setIsVisibleSMedia(true);
                 setIsVisibleCoverCircle(true);
                 setIsVisibleTopGradient(true);
                 setIsVisibleBottomGradient(true);
                 break;
             case "medias":
                setIsVisibleEllipseTop(true);
                setIsVisibleSMedia(true);
                break;
             case "contact":
                setIsVisibleEllipseTop(true);
                setIsVisibleSMedia(true);
                 break;
             case "solution":
                //  setIsVisibleEllipseTop(true);
                 setIsVisibleEllipseBottom(true);
                 break;
             case "expertises": break;
             case "legal": break;
             case "cookie": break;
             default:
                 setIsVisibleSMedia(true);
                 setIsVisibleFullGradient(true);
                 break;
         }
     }, [_pathname]);

     return (
         <span className="content-alt">
             <div className={`b-top-gradient ${!isVisibleTopGradient ? "d-none" : ""}`}>
             </div>
             <div className="b-alt-flex b-alt-center">
                <div className={`b-cover-circle ${!isVisibleCoverCircle ? "d-none" : ""}`}>
                    <img src={DirigeantJT} alt={t('Pages.Society.AltDirigeant')} />
                </div>
             </div>
             <div className={`b-bottom-gradient ${!isVisibleBottomGradient ? "d-none" : ""}`}>
             </div>
             <div className={`b-ellipse b-ellipse-top ${!isVisibleEllipseTop ? "d-none" : ""}`}>
             </div>
             <div className={`b-ellipse b-ellipse-bottom ${!isVisibleEllipseBottom ? "d-none" : ""}`}>
             </div>
             <div className={`smedia-sidebar ${!isVisibleSMedia ? "d-none" : ""}`}>
                 <div className="smedia-content">
                     <a href="https://www.linkedin.com/company/flowly1/" target="_blank" className="navlink-outside"><Linkedin size="18" /></a>
                     <a href="https://www.linkedin.com/company/flowly1/" target="_blank" className="smedia-divider"></a>
                     <a href="https://www.linkedin.com/company/flowly1/" target="_blank" className="smedia-text">{t("NavBar.FollowUs")}</a>
                 </div>
             </div>
             <div className={`b-full-gradient ${!isVisibleFullGradient ? "d-none" : ""}`}>
             </div>

         </span>
     );
};

export default BackAltered;
