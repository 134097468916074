import { NonIndexRouteObject, createBrowserRouter } from 'react-router-dom';

import Layout from './components/shared/Layout';
import Home from './components/home/Home';
import Contact from './components/contact/Contact';
import Medias from './components/medias/Medias';
import Charte from './components/charte/Charte';
import Society from './components/society/Society';
import Privacy from './components/privacy/Privacy';
import Politic from './components/politic/Politic';
import Team from './components/team/Team';
import Expertises from './components/expertises/Expertises';
import Valence from './components/valence/Valence';
import Reunion from './components/reunion/Reunion';
import Experiences from './components/experiences/Experiences';
import Avignon from './components/avignon/Avignon';
import Rouen from './components/rouen/Rouen';
import Douai from './components/douai/Douai';
import Carjaune from './components/carjaune/Carjaune';
import TheSolution from './components/the-solution/TheSolution';
import MentionsLegales from './components/mentions-legales/MentionsLegales';

export interface CustomRouteObject extends NonIndexRouteObject {
  children?: CustomRouteObject[],
  title?: string,
  visible?: boolean,
  close?: boolean
}

export const routes : CustomRouteObject[] = [
  {
    element: <Layout />,
    children: [
      // {
      //   path: "/charte",
      //   element: <Charte />
      // },
      {
        path: "/",
        title: "Pages.Home.Menu",
        visible: true,
        close: true,
        element: <Home />
    },
    {
        path: "/accueil",
        title: "Pages.Home.Menu",
        visible: true,
        close: true,
        element: <Home />
    },
      {
        path: "/expertises",
        title: "Pages.Expertises.Menu",
        close: false,
        element: <Expertises />
        // children: [
        //   {
        //     path: "",
        //     title: "Pages.Expertises.SubMenu",
        //     close: true,
        //     element: <Expertises />
        //   },
        //   {
        //     path: "experiences",
        //     title: "Pages.Experiences.Menu",
        //     close: true,
        //     element: <Experiences />
        //   },
        // ]
      },
      {
        path: "/solution",
        title: "Pages.Solution.Menu",
        close: true,
        element: <TheSolution />
        //children: [
        //  {
        //    path: "",
        //    title: "Pages.Solution.Menu",
        //    close: true,
        //    element: <TheSolution />
        //  },
        //  {
        //    path: "privacy",
        //    title: "Pages.Privacy.Menu",
        //    close: true,
        //    element: <Privacy />
        //  },
        //]
      },
      {
        path: "/societe",
        title: "Pages.Society.Menu",
        close: true,
        element: <Society />
        //children: [
        //  {
        //    path: "",
        //    title: "Pages.Society.Menu",
        //    close: true,
        //    element: <Society />
        //  },
        //  {
        //    path: "team",
        //    title: "Pages.Team.Menu",
        //    close: true,
        //    element: <Team />
        //  }
        //]
      },
      {
        path: "/medias",
        title: "Pages.Medias.Menu",
        close: true,
        element: <Medias />
      },
      {
        path: "/contact",
        title: "Pages.Contact.Menu",
        close: true,
        element: <Contact />
      },
      {
        path: "expertises/valence",
        title: "Pages.Valence.Menu",
        visible: false,
        close: false,
        element: <Valence />
      },
      {
        path: "expertises/reunion",
        title: "Pages.Reunion.Menu",
        visible: false,
        close: false,
        element: <Reunion />
      },
      {
        path: "expertises/avignon",
        title: "Pages.Avignon.Menu",
        visible: false,
        close: false,
        element: <Avignon />
      },
      {
          path: "expertises/douai",
          title: "Pages.Douai.Menu",
          visible: false,
          close: false,
          element: <Douai />
      },
      {
        path: "expertises/rouen",
        title: "Page.Rouen.Menu",
        visible: false,
        close: false,
        element: <Rouen />
      },
      {
          path: "expertises/carjaune",
          title: "Page.Carjaune.Menu",
          visible: false,
          close: false,
          element: <Carjaune />
      },
      {
        path: "/cookie",
        title: "Pages.Cookies.Menu",
        visible: false,
        close: true,
        element: <Politic />
      },
      {
        path: "/legal",
        title: "Pages.Legal.Menu",
        visible: false,
        close: true,
        element: <MentionsLegales/>
      },
        // page raccourci a enlever plus tard
      {
        path: "/equipe",
        title: "Pages.Team.Menu",
        visible: false,
        close: false,
        element: <Team />
      },
      {
        path: "/privacy",
        title: "Pages.Privacy.Menu",
        visible: false,
        close: false,
        element: <Privacy />
      }
    ]
  }
];
export const router  = createBrowserRouter(routes);
