import React, { MouseEventHandler, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Accordion, Card, Button } from 'react-bootstrap';
import { ArrowUpRight, PlusCircle, MinusCircle } from 'react-feather';

import './accordion.css';

interface ElementItem {
    altText: string;
    caption: string;
    description?: string;
    key: number;
    link: string;
    author?: string;
    icon?: string;
}

interface AccordionComponentProps {
    data: ElementItem[];
}

const handleClick = function (e: React.MouseEvent<HTMLElement>) {
    const svgPlus = document.querySelectorAll('.svg-plus');
    const svgMinus = document.querySelectorAll('.svg-minus');

    const target = e.currentTarget;

    const elPlus = target.querySelector('.svg-plus');
    const elMinus = target.querySelector('.svg-minus');
    svgPlus.forEach((el) => {
        if (el !== elPlus) {
            (el as HTMLElement).style.display = "block";
        }
    });
    svgMinus.forEach((el) => {
        if (el !== elMinus) {
            (el as HTMLElement).style.display = "none";
        }
    });

    if (elPlus) (elPlus as HTMLElement).style.display = "block";
    if (elMinus) (elMinus as HTMLElement).style.display = "none";
    if (target.classList.contains("collapsed")) {
        if (elPlus) (elPlus as HTMLElement).style.display = "none";
        if (elMinus) (elMinus as HTMLElement).style.display = "block";
    }
}


const Accordions: React.FC<AccordionComponentProps> = ({ data }) => {
    const { t, i18n } = useTranslation();
    const { pathname } = useLocation();

    return (
        <Accordion defaultActiveKey="0">
            {data.map((item, index) => (
                <Accordion.Item eventKey={index.toString()} key={index}>
                    <Accordion.Header onClick={handleClick}>
                        {item.caption}
                        <div className="accordion-icon" >
                            <PlusCircle size={24} className="svg-plus" />
                            <MinusCircle size={24} className="svg-minus" />
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p dangerouslySetInnerHTML={{ __html: item.description || "" }} />
                        <div className="btn-content text-center">
                            <a href={`/expertises${item.link}`} className="flowly-btn orange">{t("Pages.Home.ButtonMore")}<ArrowUpRight size={16} /></a>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            ))}
        </Accordion>
    );
};

export default Accordions;
