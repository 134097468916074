import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Section from '../shared/Section';
import Slider from '../shared/Slider';
import { Dirigeant, DirigeantJT, SocietyPicture } from './../../assets/img/society';

import TeamCard from './../team/TeamCard';
import { DefaultMen, DefaultWomen, DefaultYoungMen, DefaultYoungWomen } from '../../assets/img/team';
import { TeamData, TeamDataRoot } from './../team/types/TeamData';

import { PartnerData, PartnerDataRoot } from './types/PartnerData';
import Testimonials from '../shared/testimonial/Testimonials';

import './society.css';
import './../team/teamcard.css';
import ImageLoader from '../shared/loader/ImageLoader';

const Society = (): React.JSX.Element => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [langue, setlangue] = React.useState("en");
    const [loading, setLoading] = React.useState(false);
    const [teamData, setTeamData] = useState<TeamData[]>();
    const loaders = Array(10).fill(0);
    const teamUrl = "/api/team/team.json";
    const partnerUrl = "/api/partner/partner.json";
    useEffect(() => {
        let lang = "en";
        lang = localStorage.getItem("lang") === undefined ? navigator.language : localStorage.getItem("lang")!;
        setlangue(lang);
    })
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await fetch(teamUrl);
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data: TeamDataRoot = await response.json();
                
                if (langue == "fr") {
                    setTeamData(data.data_fr);

                }
                if (langue == "en") {
                    setTeamData(data.data_en);

                }
                if (langue == "sp") {
                    setTeamData(data.data_sp);
                }
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
            } catch (err) { }
        };

        fetchData();
    }, [langue]);

    const [partnerData, setPartnerData] = useState<PartnerData[]>();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(partnerUrl);
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data: PartnerDataRoot = await response.json();
                setPartnerData(data.data);
            } catch (err) { }
        };

        fetchData();
    }, []);

    return <>
    <Section className="section-society top-section">
        <Row >
            <Col xs={12} className="text-center">
                    <h2 dangerouslySetInnerHTML={
                        { __html: t('Pages.Society.Title')! }
                    } />
            </Col>
                <Col xs={0} sm={0} md={1} lg={2}></Col>
                <Col xs={12} sm={12} md={10} lg={8} className="text-center">
                    <p dangerouslySetInnerHTML={
                        { __html: t('Pages.Society.Declaration')! }
                    } />
            </Col>
                <Col xs={0} sm={0} md={1} lg={2}></Col>
        </Row>
    </Section>
    <Section className="section-society-team">
        <Row >
            <Col xs={12}>
                <h4 dangerouslySetInnerHTML={
                    { __html: t('Pages.Society.Team')! }
                } />
            </Col>
        </Row>
    </Section>
    <Section className="section-team-card">
            {
            <Row>
                {
                    loading && loaders.map((el, index) =>
                        <Col key={index} xs={12} sm={12} md={6} lg={4} xl={4} xxl={3} className="text-center mt-4" style={{ height: "300px" }}>
                            <ImageLoader />
                        </Col>
                    )
                }
                {
                    !loading && teamData?.map((group) => <>
                        {
                            group.data.map((team, index) =>
                                <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={3} className="text-center mt-4">
                                    <TeamCard job={team.job} lastname={team.lastname} firstname={team.firstname} description={team.description} link={team.link} category={team.category} img={team.img} />
                                </Col>
                            )
                        }
                    </>)
                }
            </Row>
        }
    </Section>
    <Section className="section-society society-content">
        <Row>
            <Col xs={12} className="text-center mt-4">
                    <h4 dangerouslySetInnerHTML={
                        { __html: t('Pages.Society.Partenaires') === undefined ? "" : t('Pages.Society.Partenaires') }
                    } />
            </Col>
            <Col xs={12} className="text-center mt-4">
                    <Row xs={12} className="text-center">
                        <Slider />
                    </Row>
            </Col>
        </Row>
    </Section>
    <Section className="section-society-comment">
        <Row >
            <Col xs={12} className="text-center">
                <h4 dangerouslySetInnerHTML={
                    { __html: t('Pages.Home.Testimonial')! }
                } />
            </Col>
            <Col xs={12} className="text-center">
                <Testimonials />
            </Col>
        </Row>
    </Section>
    </>
};

export default Society;