import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';

type CookieProps = {
    activate : boolean
}


const Section = ({ activate }: CookieProps): React.JSX.Element => {

    const { t } = useTranslation();
    const [isVisible, setIsVisible] = React.useState(false);

    React.useEffect(() => {
        const storedCookie = localStorage.getItem("cookie");
        if (storedCookie !== null) {
            setIsVisible(false);
        }
    }, []);

    const acceptCookies = () => {
        setIsVisible(false);
        localStorage.setItem("cookie", "true");
        // TODO, logik here
    };

    const refuseCookies = () => {
        setIsVisible(false);
        localStorage.setItem("cookie", "false");
        // TODO, logik here
    };

    return (
        <div className={`cookie-banner ${!isVisible ? "d-none" : ""}`}>
            <Row >
                <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                    <p>{t('Cookie.Description')}<a href="/cookie">{t('Cookie.Link')}</a> </p>
                </Col>
                <Col xs={12} sm={12} md={4} lg={4} xl={4} className="cookie-content-btn">
                    <button className="flowly-btn orange btn btn-primary" onClick={acceptCookies}>{t('Cookie.Accept')}</button>
                    <button className="flowly-btn red btn btn-primary" onClick={refuseCookies}>{t('Cookie.Essential')}</button>
                </Col>
            </Row>
        </div>
    );
};

export default Section;