import React from 'react';
import Container from 'react-bootstrap/Container';

type SectionProps = {
    children : React.ReactNode
    className? : string
    style? : React.CSSProperties
}

const Section = ({className, style, children} : SectionProps): React.JSX.Element => { 
    return (
      <section className={className} style={style}>
        <Container>
            {children}
        </Container>
      </section>
     
    );
};

export default Section;