import React, { useRef, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import { ArrowUpRight } from 'react-feather';

import Section from '../shared/Section';
import Slider from '../shared/Slider';
import SliderItem from '../shared/SliderItem';
import Accordions from '../shared/accordion/Accordions';
import { FlowlyAnimation, Solution, SolutionMobile, Expertise, ArtBoardLeft, ArtBoardRight, Road } from './../../assets/img/home';
import ImageLoader from '../shared/loader/ImageLoader';
import { LinkedinData, LinkedinDataRoot } from '../linkedin/types/LinkedinData';
import Testimonials from '../shared/testimonial/Testimonials';

import './home.css';
import LinkedinPost from '../linkedin/LinkedinPost';

interface ElementData {
    altText: string,
    caption: string,
    description?: string,
    key: number,
    link: string,
    author?: string,
    icon?: string
};
const linkedinPostsUrl = "/api/linkedin/linkedin.json";
const Home = (): React.JSX.Element => {

    const [loading, setLoading] = React.useState(true);
    const [linkedinData, setLinkedinData] = React.useState<LinkedinData[]>();
    const [data, setData] = React.useState<ElementData[]>([]);
    const loaders = Array(3).fill(0);

    const { t } = useTranslation();

    React.useEffect(() => {
        const fetchData = async () => {
            const imagesData: ElementData[] = [
                {
                    key: 1,
                    altText: t('Pages.Expertises.SupplyDemand.Title'),
                    caption: t('Pages.Expertises.SupplyDemand.Title'),
                    description: t('Pages.Expertises.SupplyDemand.Catch'),
                    link: '?id=Pages.Expertises.SupplyDemand.Title'
                },
                {
                    key: 2,
                    altText: t('Pages.Expertises.OD.Title'),
                    caption: t('Pages.Expertises.OD.Title'),
                    description: t('Pages.Expertises.OD.Catch'),
                    link: '?id=Pages.Expertises.OD.Title'
                },
                {
                    key: 3,
                    altText: t('Pages.Expertises.Evolve.Title'),
                    caption: t('Pages.Expertises.Evolve.Title'),
                    description: t('Pages.Expertises.Evolve.Catch'),
                    link: '?id=Pages.Expertises.Evolve.Title'
                },
                {
                    key: 4,
                    altText: t('Pages.Expertises.Prediction.Title'),
                    caption: t('Pages.Expertises.Prediction.Title'),
                    description: t('Pages.Expertises.Prediction.Catch'),
                    link: '?id=Pages.Expertises.Prediction.Title'
                },
                {
                    key: 5,
                    altText: t('Pages.Expertises.Correspondence.Title'),
                    caption: t('Pages.Expertises.Correspondence.Title'),
                    description: t('Pages.Expertises.Correspondence.Catch'),
                    link: '?id=Pages.Expertises.Correspondence.Title'
                },
                {
                    key: 6,
                    altText: t('Pages.Expertises.Fraud.Title'),
                    caption: t('Pages.Expertises.Fraud.Title'),
                    description: t('Pages.Expertises.Fraud.Catch'),
                    link: '?id=Pages.Expertises.Fraud.Title'
                },
                {
                    key: 7,
                    altText: t('Pages.Expertises.Punctuality.Title'),
                    caption: t('Pages.Expertises.Punctuality.Title'),
                    description: t('Pages.Expertises.Punctuality.Catch'),
                    link: '?id=Pages.Expertises.Punctuality.Title'
                },
                {
                    key: 8,
                    altText: t('Pages.Expertises.Traffic.Title'),
                    caption: t('Pages.Expertises.Traffic.Title'),
                    description: t('Pages.Expertises.Traffic.Catch'),
                    link: '?id=Pages.Expertises.Traffic.Title'
                },
                {
                    key: 9,
                    altText: t('Pages.Expertises.Bike.Title'),
                    caption: t('Pages.Expertises.Bike.Title'),
                    description: t('Pages.Expertises.Bike.Catch'),
                    link: '?id=Pages.Expertises.Bike.Title'
                },
                {
                    key: 10,
                    altText: t('Pages.Expertises.TAD.Title'),
                    caption: t('Pages.Expertises.TAD.Title'),
                    description: t('Pages.Expertises.TAD.Catch'),
                    link: '?id=Pages.Expertises.TAD.Title'
                },
                {
                    key: 11,
                    altText: t('Pages.Expertises.Information.Title'),
                    caption: t('Pages.Expertises.Information.Title'),
                    description: t('Pages.Expertises.Information.Catch'),
                    link: '?id=Pages.Expertises.Information.Title'
                }
            ];
            setData(imagesData);
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        };

        const fetchLinkedinPost = async () => {
            try {
                setLoading(true);
                const response = await fetch(linkedinPostsUrl);
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data: LinkedinDataRoot = await response.json();
                setLinkedinData(data.data);
                setTimeout(()=>{
                    setLoading(false);
                }, 1000);
            } catch (err) {}
        }
        fetchData().catch(console.error);
        fetchLinkedinPost();
    }, [t]);

    const navigatorScreenHeight = window.innerHeight;
    const getSize = () => {
        const navbarElement = document.querySelector('.flowly-navbar');
        if (navbarElement) {
            if (navbarElement) {
                const navbarHeight = (navbarElement as HTMLElement).offsetHeight;
                const fullScreenContent = navigatorScreenHeight - navbarHeight;
                if (fullScreenContent > 0) {
                    document.querySelector('.full-screen')?.setAttribute('style', `height: ${fullScreenContent}px`);
                    document.querySelector('.full-screen .container')?.setAttribute('style', `height: 100%`);
                    document.querySelector('.full-screen .container .flowly-animation')?.setAttribute('style', `height: 100%`);
                    document.querySelector('.full-screen .container .flowly-animation .top-content')?.setAttribute('style', `height: 100%;display:flex;flex-direction:column;align-items:center;`);
                    document.querySelector('.full-screen .container .flowly-animation .flowly-subtitle')?.setAttribute('style', `top: -32%`);
                    document.querySelector('.full-screen .container .flowly-animation .flowly-scroll-indicator')?.setAttribute('style', `top: -28%`);
                    
                };
            }
        }
    };
    document.querySelector('.linkedin-section')?.setAttribute('style', `background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.9) 50%,
        rgba(255, 255, 255, 0.9) 50%
      ), url(${Road})`);

    const handleAncor = () => {
        const targetSection = document.querySelector('.flowly-smooth-section');
        //if (targetSection) targetSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
        if (targetSection) {
            const sectionTop = targetSection.getBoundingClientRect().top;
            const offset = 70;
            const absoluteSectionTop = window.pageYOffset + sectionTop - offset;

            window.scrollTo({
                top: absoluteSectionTop,
                behavior: 'smooth'
            });
        }
    };

    useEffect(() => {
        getSize();
    }, []);

    return <>
        <Section className='home-section top-section text-center full-screen'>
            <Row className='align-items-start flowly-animation'>
                <Col lg={0} xl={1}></Col>
                <Col lg={12} xl={6} className="text-center column-top-section top-content order-xl-2">
                    <img src={FlowlyAnimation} alt="animation-flowly" />
                </Col>
                <Col lg={12} xl={5} className="column-top-section top-text text-center order-xl-1">
                    <div className='flowly-subtitle text-left'>
                        <h3 style={{ color: 'black' }} dangerouslySetInnerHTML={
                            { __html: t('Pages.Home.Subtitle') === undefined ? "" : t('Pages.Home.Subtitle') }
                        } />
                        <p>{t('Pages.Home.Description')}</p>
                    </div>
                    <div className="btn-content text-left">
                        <a href="/solution" className="flowly-btn orange">{t("Pages.Home.ButtonMore")}<ArrowUpRight size={16} /></a>
                    </div>
                </Col>
            </Row>
        </Section>
        <Section style={{ paddingBottom: "100px" }} className='home-section text-center pt-4 home-section-solution'>
            <Row className="py-4 content-section-solution">
                <Col xs={12} className="text-center">
                    <h3>{t('Pages.Home.Title-LaSolution')}</h3>
                </Col>
                <Col xs={12} className="text-center">
                    <h5 dangerouslySetInnerHTML={
                        { __html: t('Pages.Home.Subtitle-LaSolution') === undefined ? "" : t('Pages.Home.Subtitle-LaSolution') }
                    } />
                </Col>
            </Row>
            <Row className="content-section-solution">
                <Col xs={1} lg={2}></Col>
                <Col xs={12} className='text-center solution-home'>
                    <img src={Solution} alt={t('Pages.Home.AgregationData')} className='img-fluid' />
                    <p className="d-none">{t('Pages.Home.DisponibilityData')}</p>
                </Col>
                <Col xs={12} className='text-center solution-home-mobile'>
                    <img src={SolutionMobile} alt={t('Pages.Home.AgregationData')} className='img-fluid' />
                    <p className="d-none">{t('Pages.Home.DisponibilityData')}</p>
                </Col>
                <Col xs={1} lg={2}></Col>
            </Row>
            <Row className="btn-solo content-section-solution">
                <Col xs={12}>
                    <div className="btn-content text-center">
                        <a href="/solution" className="mt-2 flowly-btn red">{t("Pages.Home.ButtonMore")}<ArrowUpRight size={16} /></a>
                    </div>
                </Col>
            </Row>
        </Section>
        <Section className="pb-4 home-section home-expertise">
            <Row>
                <Col xs={12} lg={4} className="text-center">
                    <h3>{t('Pages.Home.Expertise')}</h3>
                    <img src={Expertise} alt={t('Pages.Home.Expertise')} className='img-fluid img-expertise' />
                </Col>
                <Col xs={12} lg={8} className="text-center">
                    <Accordions data={data} />
                </Col>
            </Row>
        </Section>
        <Section className='linkedin-section carousel-section text-center py-4'>
            <div className="bg-img"></div>
            <Row>
                <Col xs={12} className="text-center">
                    <h3>{t('Pages.Home.Media')}</h3>
                </Col>
                <Col xs={12} className="text-center">
                    {
                        loading && <Row>
                            {
                                loaders.map((loader, index)=>
                                    <Col key={index} xs={12} lg={4} style={{height: "150px"}}>
                                        <ImageLoader />
                                    </Col>
                                )
                            }
                        </Row>
                    }
                    {
                        !loading && <Row>
                            {
                                linkedinData?.map((post, index)=>
                                    <Col key={index} xs={12} md={4} className='p-2'>
                                        <LinkedinPost post={post} />
                                    </Col>
                                )
                            }
                        </Row>
                    }
                </Col>
            </Row>
        </Section>
        <Section className='home-section text-center flowly-home-partner py-4'>
            <Row>
                <Col xs={12} className="text-center">
                    <h3>{t('Pages.Home.Partnership')}</h3>
                </Col>
                <Col xs={12} className="text-center">
                    <p className="text-center">{t('Pages.Home.ExperienceDescription')}</p>
                </Col>
                <Col xs={2}></Col>
                </Row>
                <Row xs={12} className="text-center">
                    <Slider />
                </Row>

        </Section>
        <Section className='home-section text-center flowly-home-testimonial py-4'>
            <div className="img-title">
                <img src={ArtBoardLeft} alt="ArtBoardLeft" className="img-left" />
                <img src={ArtBoardRight} alt="ArtBoardRight" className="img-right" />
            </div>
            <Row>
                <Col xs={12} className="text-center">
                    <h3>{t('Pages.Home.Testimonial')}</h3>
                </Col>
                <Col xs={2}></Col>
                <Col xs={2}></Col>
            </Row>

            <Row>
                <Col xs={12} className="text-center">
                    <Testimonials />
                </Col>
            </Row>
        </Section>
    </>
};

export default Home;