import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useTranslation } from 'react-i18next';

import Section from '../shared/Section';
import Media from './Media';
import { MediaData, MediaDataRoot } from './types/MediaData';

import './media.css';
import ImageLoader from '../shared/loader/ImageLoader';
import { Map, MediaDefault } from '../../assets/img/media';

const mediaUrl = "api/media/media.json";

const Medias = (): React.JSX.Element => {

    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false);
    const [medias, setMedias] = React.useState<MediaData[]>();
    const loaders = Array(10).fill(0);

    React.useEffect(()=>{
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await fetch(mediaUrl);
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data: MediaDataRoot = await response.json();
                setMedias(data.data);
                setTimeout(()=>{
                    setLoading(false);
                }, 1000);
            } catch (err) {}
        };
        fetchData();
    }, [])

    return <>
        <Section className='page-media-section py-4 position-relative'>
            <Row className='justify-content-center text-center py-4'>
                <Col lg={6} xs={12} style={{zIndex: 98}} className='fading-white-bg'>
                    <h2 dangerouslySetInnerHTML={{__html: t('Pages.Medias.Title')!}}></h2>
                    <p dangerouslySetInnerHTML={{__html: t('Pages.Medias.SubTitle')!}}></p>
                </Col>
            </Row>
            <img className='map-img' src={Map}/>
        </Section>
        <Section className='page-media-section py-4 pb-5'>
            <Row className='medias-wrapper'>
                {
                    loading && loaders.map((loader, index)=>
                        <Col key={index} xs={12} md={6} xl={4} style={{height: "300px", padding: "0.75em", zIndex: 98}}>
                            <ImageLoader />                          
                        </Col>
                    )
                }
                {
                    !loading && <Tabs style={{zIndex: 98}}>
                    {
                        medias?.map((year)=> 
                            <Tab eventKey={year.year} className='fading-white-bg' title={year.year}>
                                <Row className='justify-content-center mt-3'>
                                {
                                    year.data.map((media, index)=> 
                                        <Col key={index} xs={12} md={6} xl={4} className='p-0'>
                                            <Media media={media}/>                            
                                        </Col>
                                    )
                                }
                                </Row>
                            </Tab>
                        )
                    }
                    </Tabs>
                }
            </Row>
        </Section>
    </>
};

export default Medias;