import  React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Section from '../shared/Section';
import Accordion from 'react-bootstrap/Accordion';
import Table from "react-bootstrap/Table";
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';





const Politic = (): React.JSX.Element => {
    const { t } = useTranslation();
   
    return <>
        <Section className="politic-section">
            <Row className='text-center'>
                <Col xs={0} lg={2}></Col>
                <Col xs={12} lg={8}>
                    <h2 dangerouslySetInnerHTML={
                        { __html: t('Pages.Cookies.Title') === undefined ? "" : t('Pages.Cookies.Title') }
                    } />
                    <p dangerouslySetInnerHTML={
                        { __html: t('Pages.Cookies.SubTitle') === undefined ? "" : t('Pages.Cookies.SubTitle') }
                    } />

                </Col>

            </Row>
        
                        {/*Introduction*/ }
        
            <Row >
                <Col xs={0} lg={2}></Col>
                <Col xs={12} lg={8}>
                    <h5 dangerouslySetInnerHTML={
                        { __html: t('Pages.Cookies.IntroTitle') === undefined ? "" : t('Pages.Cookies.IntroTitle') }
                    } />
                    <p style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={
                        { __html: t('Pages.Cookies.noCookie') === undefined ? "" : t('Pages.Cookies.noCookie') }
                    } />
                </Col>
            </Row>

        
                        {/*LocalStorage*/ }
        

            <Row>
                <Col xs={0} lg={2}></Col>
                <Col xs={12} lg={8}>
                    <h5 dangerouslySetInnerHTML={
                        { __html: t('Pages.Cookies.LocalStorage') === undefined ? "" : t('Pages.Cookies.LocalStorage') }} />
                    <p style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={
                        { __html: t('Pages.Cookies.LocalStorageDetail') === undefined ? "" : t('Pages.Cookies.LocalStorageDetail') }} />
                    <ul>
                        <li style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={
                            { __html: t('Pages.Cookies.LocalStorage1') === undefined ? "" : t('Pages.Cookies.LocalStorage1') }} />                       
                        <li style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={
                            { __html: t('Pages.Cookies.LocalStorage2') === undefined ? "" : t('Pages.Cookies.LocalStorage2') }} />                       
                        
                    </ul>
                    
                </Col>
            </Row>


            {/*Gestioncookie*/}


            <Row>
                <Col xs={0} lg={2}></Col>
                <Col xs={12} lg={8}>
                    <h5 dangerouslySetInnerHTML={
                        { __html: t('Pages.Cookies.Gestion') === undefined ? "" : t('Pages.Cookies.Gestion') }} />
                    <p style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={
                        { __html: t('Pages.Cookies.GestionDetail') === undefined ? "" : t('Pages.Cookies.GestionDetail') }} />
                    <ul>
                        <li style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={
                            { __html: t('Pages.Cookies.Gestion1') === undefined ? "" : t('Pages.Cookies.Gestion1') }} />
                        <li style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={
                            { __html: t('Pages.Cookies.Gestion2') === undefined ? "" : t('Pages.Cookies.Gestion2') }} />

                        <li style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={
                            { __html: t('Pages.Cookies.Gestion3') === undefined ? "" : t('Pages.Cookies.Gestion3') }} />
                        <li style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={
                            { __html: t('Pages.Cookies.Gestion4') === undefined ? "" : t('Pages.Cookies.Gestion4') }} />

                    </ul>
                        <p style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={
                            { __html: t('Pages.Cookies.GestionEnd') === undefined ? "" : t('Pages.Cookies.GestionEnd') }} />

                </Col>
            </Row>

            {/*Changement*/}

            <Row >
                <Col xs={0} lg={2}></Col>
                <Col xs={12} lg={8}>
                    <h5 dangerouslySetInnerHTML={
                        { __html: t('Pages.Cookies.Change') === undefined ? "" : t('Pages.Cookies.Change') }
                    } />
                    <p style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={
                        { __html: t('Pages.Cookies.TextChange') === undefined ? "" : t('Pages.Cookies.TextChange') }
                    } />
                </Col>
            </Row>

            {/*Contact*/}

            <Row >
                <Col xs={0} lg={2}></Col>
                <Col xs={12} lg={8}>
                    <h5 dangerouslySetInnerHTML={
                        { __html: t('Pages.Cookies.Contact') === undefined ? "" : t('Pages.Cookies.Contact') }
                    } />
                    <p style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={
                        { __html: t('Pages.Cookies.TextContact') === undefined ? "" : t('Pages.Cookies.TextContact') }
                    } />
                </Col>
            </Row>

        </Section>
    </>
}
export default Politic