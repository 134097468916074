import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';

import SliderItem from '../shared/SliderItem';
import { PartnerData, PartnerDataRoot } from '../society/types/PartnerData';

type SliderProps = {
    className?: string
    style?: React.CSSProperties
}

const partnerUrl = "/api/partner/partner.json";
const Slider = ({ className, style }: SliderProps): React.JSX.Element => {

    const [partnerData, setPartnerData] = useState<PartnerData[]>();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(partnerUrl);
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data: PartnerDataRoot = await response.json();
                setPartnerData(data.data);
            } catch (err) { }
        };

        fetchData();
    }, []);

    return <>
        <div className="slider">
            <div className="slide-track">
                {
                    partnerData?.map((group) =>
                        group.data.map((partner, index) =>
                            <div id='imageslide'>
                                <SliderItem url={partner.url} img={partner.imgSrc} height="auto" margin-left='5px' width='80%' alt="" />
                            </div>
                        )
                    )
                }
                {
                    partnerData?.map((group) =>
                        group.data.map((partner, index) =>
                            <div id='imageslide'>
                                <SliderItem url={partner.url} img={partner.imgSrc} height="auto" margin-left='5px' width='80%' alt="" />
                            </div>
                        )
                    )
                }
            </div>
        </div>
    </>;
};

export default Slider;