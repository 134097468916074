import React from 'react';
import Container from 'react-bootstrap/Container';
import { Linkedin } from 'react-feather';

import { DefaultMen, DefaultWomen, DefaultYoungMen, DefaultYoungWomen } from '../../assets/img/team';

type SectionProps = {
    job?: string
    lastname?: string
    firstname?: string
    img?: string
    description?: string
    link?: string
    category?: string
}

const TeamCard = ({ job, lastname, firstname, img, description, link, category }: SectionProps): React.JSX.Element => {
    let imgDefault = '';
    switch (category) {
        case "men":
            imgDefault = DefaultMen;
            break;
        case "women":
            imgDefault = DefaultWomen;
            break;
        case "young-men":
            imgDefault = DefaultYoungMen;
            break;
        case "young-women":
            imgDefault = DefaultYoungWomen;
            break;
        default:
            imgDefault = DefaultMen;
            break;
    };
    img == null ? img = imgDefault : img = img;

    return (
        <div className="team-card">
            <div className="img-team-content">
                <img src={img} alt={`Equipe flowly : ${lastname} ${firstname}`} />
            </div>
            <div className="team-card-hover">
                <div className="team-top">
                    <p>{job}</p>
                    <h6>{firstname} </h6>
                </div>
                <div className="team-body">
                    <p>{description}</p>
                </div>
                <div className="team-bottom">
                    {link != null ? (
                        <div className="team-social-media">
                            <a href={link} target="_blank">
                                <Linkedin color="#fff" size={14} />
                            </a>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default TeamCard;